import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import ImageComponent from 'Components/Image';
import Search from 'Assets/svg/search.svg';
import Expand from 'Assets/svg/expand.svg';
import s from './Image.module.scss';

const Image = ({
    image,
    index,
    id,
    openModal,
}) => {
    const {t} = useTranslation();
    const sizes = index === 0 ? [
        '(min-width: 1024px) 618px',
        '(min-width: 768px) 523px',
        '(min-width: 500px) 436px',
        '100vw',
    ] : [
        '(min-width: 1024px) 140px',
        '(min-width: 768px) 118px',
        '(min-width: 500px) 210px',
        '50vw'
    ];
    return (
        <button
            className={s['GalleryImage']}
            aria-controls={id}
            aria-label={t('gallery.zoom')}
            onClick={() => openModal(index)}
        >
            <ImageComponent
                {...image}
                className={s['GalleryImage__Image']}
                sizes={sizes}
                useCover={true}
            />

            <span className={s['GalleryImage__Overlay']}>
                <Search />
            </span>
            {index === 0 &&
                <span className={s['GalleryImage__Expand']}>
                    <Expand />
                </span>
            }
        </button>
    );
};

Image.propTypes = {
    image: PropTypes.object,
    index: PropTypes.number,
    id: PropTypes.string,
    openModal: PropTypes.func,
};

Image.defaultProps = {
    image: {},
    index: 0,
    id: '',
    openModal: null,
};

export default Image;
