import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import _ from 'lodash';
import classNames from 'classnames';
import { AppContext } from 'Layouts/App';
import { AnimateInOut } from 'Components/Animate';
import Image from './Image';
import Search from 'Assets/svg/search.svg';

const GalleryModal = dynamic(() => import('Components/GalleryModal'), {ssr: false});

import s from './CoreGallery.module.scss';

const CoreGallery = ({identifier, images, caption}) => {
    const {isSSR} = useContext(AppContext);

    const {t} = useTranslation();

    const [mounted, setMounted] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if(modalIsOpen) {
            document.querySelector('html,body').classList.add('no-scroll');
        } else {
            document.querySelector('html,body').classList.remove('no-scroll');
        }
    }, [modalIsOpen]);

    const openModal = (index) => {
        setModalIsOpen(true);
        setCurrentIndex(index);
    };

    const closeModal = (e) => {
        e.preventDefault();
        setModalIsOpen(false);
        setCurrentIndex(0);
    };

    // Remove any empty images
    const filterImages = [...images].filter((image) => !_.isEmpty(image));

    const galleryId = `Gallery-${identifier}`;
    const maxVisibleImages = 5;
    const showMore = filterImages.length >= maxVisibleImages;
    const nrNotShowing = filterImages.length - maxVisibleImages + 1;
    const modalIndex = showMore ? 4 : filterImages.length - 1;

    const thumbnails = [...filterImages].filter((image, index) => {
        return showMore ? (index < maxVisibleImages - 1) : (index < maxVisibleImages);
    });
    return (
        <div className={s['Gallery']} id={galleryId}>
            <figure className={s['Gallery__Figure']}>
                <ul className={s['Gallery__Content']}>
                    {thumbnails && thumbnails.map((image, index) => (
                        <li className={s['Gallery__Item']} key={index}>
                            <Image
                                index={index}
                                image={image}
                                id={`${galleryId}-item-${index}`}
                                openModal={openModal}
                            />
                        </li>
                    ))}

                    <div
                        className={classNames(s['Gallery__Item'], s['Gallery__Item--ShowMore'])}
                        onClick={() => openModal(modalIndex)}
                    >
                        <span className={s['Gallery__ShowMore']}>
                            {showMore &&
                                <Fragment>
                                    <span className={s['Gallery__NotShowing']}>{`+${nrNotShowing}`}</span>
                                    {t('gallery.showMore')}
                                </Fragment>
                            }
                            {!showMore &&
                                <Fragment>
                                    <span className={s['Gallery__Icon']}><Search /></span>
                                    {t('gallery.showAll')}
                                </Fragment>
                            }
                        </span>
                    </div>
                </ul>

                {!_.isEmpty(caption) &&
                    <figcaption className={s['Gallery__Caption']}>{caption}</figcaption>
                }
            </figure>

            {mounted && !isSSR &&
                <AnimateInOut
                    isVisible={modalIsOpen}
                    duration={600}
                    delay={100}
                >
                    <div className={s['Gallery__Modal']}>
                        <GalleryModal
                            galleryId={galleryId}
                            images={filterImages}
                            currentIndex={currentIndex}
                            setCurrentIndex={setCurrentIndex}
                            isOpen={modalIsOpen}
                            closeModal={closeModal}
                        />
                    </div>
                </AnimateInOut>
            }
        </div>
    );
};

CoreGallery.propTypes = {
    images: PropTypes.array,
    identifier: PropTypes.number,
    caption: PropTypes.string,
};

CoreGallery.defaultProps = {
    images: [],
    identifier: 0,
    caption: '',
};

export default CoreGallery;
