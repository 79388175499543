import dynamic from 'next/dynamic';

// Only add components that will be needed in Gutenberg
import Chart from './Chart';
import CoreEmbed from './CoreEmbed';
import CoreGallery from './CoreGallery';
import CoreImage from './CoreImage';
import GravityFormComp from './GravityFormComp';
// import Newsletter from './Newsletter';
// import MemberSurvey from './MemberSurvey';
// import Snippet from './Snippet';
// import SacoLink from './SacoLink';

// Lazy load components not needed for SSR and require js to work
// const Chart = dynamic(() => import('./Chart'), {ssr: false});
// const CoreEmbed = dynamic(() => import('./CoreEmbed'), {ssr: false});
const Newsletter = dynamic(() => import('./Newsletter'), {ssr: false});
const MemberSurvey = dynamic(() => import('./MemberSurvey'), {ssr: false});
const SacoLink = dynamic(() => import('./SacoLink'), {ssr: false});
const Snippet = dynamic(() => import('./Snippet'), {ssr: false});

export {
    Chart,
    CoreEmbed,
    CoreGallery,
    CoreImage,
    GravityFormComp,
    Newsletter,
    MemberSurvey,
    Snippet,
    SacoLink,
};
