/**
 * EmbedMessage
 */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import s from './EmbedMessage.module.scss';

const EmbedMessage = ({src, showEmbed, hideMessage, isBottom}) => {
    const {t} = useTranslation();

    useEffect(() => {
        // Hide message when cookie script values are changed
        window.addEventListener('CookieScriptAccept', (e) => {
            const categories = _.get(e, 'detail.categories', []);
            if(categories.includes('targeting')) {
                hideMessage();
            }
        });
        window.addEventListener('CookieScriptAcceptAll', () => {
            hideMessage();
        });
    }, []);

    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = src.match(regExp);
    const videoId = match && match[2].length === 11 ? match[2] : '';
    const url = `https://www.youtube.com/watch?v=${videoId}`;

    const style = {};
    if(videoId !== '') {
        style.backgroundImage = `url('https://img.youtube.com/vi/${videoId}/maxresdefault.jpg')`;
    }

    const classes = classNames(
        [s['EmbedMessage']],
        {[s['EmbedMessage--Bottom']]: isBottom},
    );

    return (
        <div className={classes}>
            <div className={s['EmbedMessage__Content']}>
                <div
                    className={s['EmbedMessage__Text']}
                    dangerouslySetInnerHTML={{__html: t('embedMessage.msg')}}
                />

                <button
                    className={s['EmbedMessage__Button']}
                    onClick={() => [
                        hideMessage(),
                        showEmbed(),
                    ]}
                    type="button"
                ><span>{t('embedMessage.show')}</span></button>

                <a className={s['EmbedMessage__Link']} href={url}>
                    {t('embedMessage.goTo')}
                </a>
            </div>
        </div>
    );
};

EmbedMessage.propTypes = {
    src: PropTypes.string,
    showEmbed: PropTypes.func,
    hideMessage: PropTypes.func,
    isBottom: PropTypes.bool,
};

EmbedMessage.defaultProps = {
    src: '',
    showEmbed: () => {},
    hideMessage: () => {},
    isBottom: false,
};

export default EmbedMessage;
