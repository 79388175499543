import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import * as Components from 'Components/GutenbergComponents';
import s from './GutenbergContent.module.scss';

const GutenbergContent = ({
    components,
    modifier,
    contentMaxWidth,
    isArticle,
    isArticleLeft,
    isArticleRight,
    isLongread,
}) => {

    const classes = classNames(
        [s['GutenbergContent']],
        {[s[`GutenbergContent--${modifier}`]]: modifier},
        {[s['GutenbergContent--Article']]: isArticle},
        {[s['GutenbergContent--ArticleLeft']]: isArticleLeft},
        {[s['GutenbergContent--ArticleRight']]: isArticleRight},
        {[s['GutenbergContent--Longread']]: isLongread},
    );

    return (
        <div className={classes}>
            <div className={s['GutenbergContent__Blocks']}>
                {components.map((component, index) => (
                    <Item
                        key={index}
                        identifier={index}
                        contentMaxWidth={contentMaxWidth}
                        {...component}
                    />
                ))}
            </div>
        </div>
    );
};

GutenbergContent.propTypes = {
    components: PropTypes.array.isRequired,
    modifier: PropTypes.string,
    contentMaxWidth: PropTypes.number,
    isArticle: PropTypes.bool,
    isArticleLeft: PropTypes.bool,
    isArticleRight: PropTypes.bool,
    isLongread: PropTypes.bool,
};

GutenbergContent.defaultProps = {
    components: [],
    modifier: '',
    contentMaxWidth: null,
    isArticle: false,
    isArticleLeft: false,
    isArticleRight: false,
    isLongread: false,
};

const Item = ({componentName, componentProps, content, identifier, contentMaxWidth}) => {
    if (_.isEmpty(componentName)) {
        return null;
    }

    const Component = Components[componentName];

    const innerHTML = Component ? null : {__html: componentProps.innerHTML};

    const align = _.get(componentProps, 'attrs.align', '');

    const classes = classNames(
        [s['GutenbergContent__Block']],
        [s[`GutenbergContent__Block--${componentName}`]],
        {[s[`GutenbergContent__Block--Align${_.upperFirst(align)}`]]: align},
        // For global styling
        'Gutenberg',
        [`Gutenberg--${componentName}`],
    );

    // Possibility to pass a string or rendered react element directly
    if(!_.isEmpty(content)) {
        return (
            <div className={classes}>
                {content}
            </div>
        );
    }

    return (
        <div
            className={classes}
            dangerouslySetInnerHTML={innerHTML}
        >
            {Component &&
                <Component
                    {...componentProps}
                    identifier={identifier}
                    align={align}
                    contentMaxWidth={contentMaxWidth}
                />
            }
        </div>
    );
};

Item.propTypes = {
    componentName: PropTypes.string.isRequired,
    componentProps: PropTypes.object,
    identifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]),
    contentMaxWidth: PropTypes.number,
};

Item.defaultProps = {
    componentName: '',
    componentProps: {},
    identifier: 0,
    content: null,
    contentMaxWidth: null,
};

export default GutenbergContent;