import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazy-load';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import _ from 'lodash';
import Image from 'Components/Image';
const Video = dynamic(() => import('../Video'), {ssr: false});
import EmbedMessage from 'Components/EmbedMessage';
import s from './CoreEmbed.module.scss';

const CoreEmbed = ({
    innerHTML,
    title,
    caption,
    providerNameSlug,
    url,
    type,
    className,
    background,
    image,
    showMessage,
}) => {
    const {t} = useTranslation();

    const showImage = !_.isEmpty(image);

    const [showVideo, setShowVideo] = useState(false);
    const [showMsg, setShowMsg] = useState(showMessage);

    let provider = '';
    let backgroundImage = background;
    if(providerNameSlug === 'youtube' || url.includes('youtube') || url.includes('youtu.be')) {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        if(_.isEmpty(backgroundImage)) {
            backgroundImage = `https://i.ytimg.com/vi_webp/${match[2]}/sddefault.webp`;
        }
        provider = 'youtube';
    } else if(providerNameSlug === 'vimeo' || url.includes('vimeo')) {
        provider = 'vimeo';
    }

    const style = {
        'backgroundImage': backgroundImage !== '' ? `url('${backgroundImage}')` : '',
    };

    // Remove all tags and only the text in the figcaption should be left
    const sanitizedCaption = !_.isEmpty(caption) ? caption : SanitizeHTML(innerHTML, {
        allowedTags: [],
        transformTags: {
            'div': function() {
                return {text: ''};
            },
        },
    });

    const classes = classNames(
        [s['Embed']],
        {[s['Embed--ShowVideo']]: showVideo},
        {[s[`Embed--${_.upperFirst(type)}`]]: type},
        {[s[`Embed--${_.upperFirst(provider)}`]]: provider},
        className.split(' ').map(c => {
           return [s[`Embed--${_.upperFirst(c.replace('wp-', ''))}`]];
        }),
    );

    return (
        <div className={classes}>
            {!_.isEmpty(title) &&
                <h2 className={s['Embed__Title']}><span>{title}</span></h2>
            }
            <figure className={s['Embed__Figure']}>
                <div className={s['Embed__Block']} style={style}>
                    {showImage &&
                        <div className={s['Embed__Image']}>
                            <Image {...image} useCover={true} />
                        </div>
                    }
                    <div className={s['Embed__Video']}>
                        <LazyLoad height={400} offset={200} once className={s['Embed__LazyLoad']}>
                            <div className={s['Embed__Player']}>
                                {!showMsg &&
                                    <Video
                                        url={url}
                                        playing={showImage && showVideo}
                                    />
                                }
                            </div>
                        </LazyLoad>
                    </div>

                    {!showMsg && !showVideo &&
                        <button className={s['Embed__Button']} onClick={() => setShowVideo(true)}>
                            <span className="sr-only">{t('embed.showVideo')}</span>
                        </button>
                    }

                    {showMsg &&
                        <div className={s['Embed__Message']}>
                            <EmbedMessage
                                src={url}
                                isBottom={!_.isEmpty(title)}
                                hideMessage={() => setShowMsg(false)}
                                showEmbed={() => setShowVideo(true)}
                            />
                        </div>
                    }
                </div>

                {!_.isEmpty(sanitizedCaption) &&
                    <figcaption className={s['Embed__Figcaption']}>{sanitizedCaption}</figcaption>
                }
            </figure>
        </div>
    );
};

CoreEmbed.propTypes = {
    title: PropTypes.string,
    innerHTML: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    providerNameSlug: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    background: PropTypes.string,
    image: PropTypes.object,
    caption: PropTypes.string,
    showMessage: PropTypes.bool,
};

CoreEmbed.defaultProps = {
    title: '',
    innerHTML: '',
    url: '',
    providerNameSlug: '',
    type: '',
    className: '',
    background: '',
    image: null,
    caption: '',
    showMessage: false,
};

export default CoreEmbed;
