/* global dataLayer */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
// Used for adding locale to datepicker in react-gravity-form
import { registerLocale } from  'react-datepicker';
import sv from 'date-fns/locale/sv';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeBasicTags} from 'utils/html';
import ReactSelect from 'Components/ReactSelect';
import s from './GravityFormComp.module.scss';

const GravityForm = dynamic(() => import('react-gravity-form'), {ssr: false});
const Dropzone = dynamic(() => import('./Dropzone'), {ssr: false});
const ReCaptcha = dynamic(() => import('./ReCaptcha'), {ssr: false});
const Radio = dynamic(() => import('./Radio'), {ssr: false});
const Checkbox = dynamic(() => import('./Checkbox'), {ssr: false});

registerLocale('sv', sv);

const GravityFormComp = ({
    formId,
    backendUrl,
    title,
    description,
    customComponents,
}) => {
    const {t} = useTranslation();

    const containerRef = useRef();

    const [custom, setCustom] = useState({});
    const [submitMsg, setSubmitMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [uniqueId, setUniqueId] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState({});

    // These are only used to handle edge cases in multi file upload
    const [customFormError, setCustomFormError] = useState({});
    const [forceDisableLoading, setForceDisableLoading] = useState(false);

    // Generate a unique id for this submission, is needed for multiple file uploads
    useEffect(() => {
        const generateUniqueID = () => {
            return 'xxxxxxxx'.replace(/[xy]/g, function (c) {
                const r = Math.random() * 16 | 0, v = c === 'x' ? r : r & 0x3 | 0x8;
                return v.toString(16);
            });
        };
        setUniqueId(generateUniqueID);
    }, []);

    // When unique id is set, the custom components can be created and then the form will be loaded
    useEffect(() => {
        // Used to add files to a list for dropzone files and then be uploaded to form
        const addFile = (fieldId, tempName, fileName) => {
            // Get current input files and add to uploaded files list
            const inputName = `input_${fieldId}`;
            setUploadedFiles(files => {
                const inputFiles = _.get(files, inputName, []);
                return {
                    ...files,
                    [inputName]: [...inputFiles, {
                        temp_filename: tempName,
                        uploaded_filename: fileName,
                    }],
                };
            });
        };

        const removeFile = (fieldId, tempName) => {
            // Get current input files and add to uploaded files list
            const inputName = `input_${fieldId}`;
            setUploadedFiles(files => {
                const inputFiles = _.get(files, inputName, []);
                const newInputFiles = [...inputFiles].filter(
                    ({temp_filename}) => temp_filename !== tempName
                );
                if(_.isEmpty(newInputFiles)) {
                    const newFiles = _.omit({...files}, inputName);
                    return newFiles;
                } else {
                    return {
                        ...files,
                        [inputName]: newInputFiles,
                    };
                }
            });
        };

        const CustomMultiFileupload = (props) => (
            <Dropzone
                {...props}
                addFile={addFile}
                removeFile={removeFile}
                uniqueId={uniqueId}
                setCustomFormError={setCustomFormError}
                containerRef={containerRef}
            />
        );

        // Make sure it is only run once
        // None of these fields can receive states since they won't be updated
        if(_.isEmpty(custom) && !_.isEmpty(uniqueId) && !_.isEmpty(customComponents)) {
            setCustom(_.mapValues(customComponents, (type) => {
                switch(type) {
                case 'captcha':
                    return CustomReCaptcha;
                case 'multifileupload':
                    return CustomMultiFileupload;
                case 'radio':
                    return Radio;
                case 'checkbox':
                    return Checkbox;
                default:
                    return null;
                }
            }));
        }
    }, [uniqueId, customComponents, custom, setCustomFormError, errorMsg]);

    // Not so pretty, but this will alter some of the markup from react-gravity-form,
    // either because of copy changes or errors
    const changeFormMarkup = (form) => {
        const labels = form.querySelectorAll('label:not(.dzu-inputLabel)');
        for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            // If label text is same as html it is just a regular text
            // Otherwise try to convert the innerhtml to a new html object using lodash
            // Strings may contain escaped and unescaped html, e.g. 'Label &lt;br&gt;<abbr>*</abbr>'
            if(label.innerText !== label.innerHTML) {
                label.innerHTML = sanitizeBasicTags(_.unescape(label.innerHTML));
            }
        }

        // Make changes to file upload
        const fileupload = form.querySelectorAll('.fileupload');
        for (let i = 0; i < fileupload.length; i++) {

            // Change copy from english to swedish
            const button = fileupload[i].querySelector('.fileUpload button');
            if(button) {
                button.innerHTML = t('gravityFormComp.uploadButton');
                const nextSpan = button.nextSibling;
                if(nextSpan) {
                    nextSpan.innerHTML = t('gravityFormComp.noFileChosen');
                }
            }

            // Fix bug where accept-attribute in input file upload is "." and
            // makes it impossible to select files in Firefox
            const fileInput = fileupload[i].querySelector('input[type="file"][accept="."]');
            if(fileInput) {
                fileInput.removeAttribute('accept');
            }
        }
    };

    // When error messages are updated, a submit might have been triggered,
    // and markup will have resetted
    useEffect(() => {
        if(containerRef.current) {
            const form = containerRef.current.querySelector('form');
            if (form && form.length) {
                changeFormMarkup(form);
            }
        }
    }, [errorMsg]);

    // Custom way of checking if the form as actually loaded
    useEffect(() => {
        const onFormLoaded = (form) => {
            changeFormMarkup(form);

            setForceDisableLoading(false);
        };

        // Handle changes to the form once it has been loaded
        const checkExist = setInterval(function() {
            if(containerRef.current) {
                const form = containerRef.current.querySelector('form');
                if (form && form.length) {
                    clearInterval(checkExist);
                    onFormLoaded(form);
                }
            }
        }, 100);
    }, [t]);

    // When submit msg is set the form is completed and should scroll into view
    useEffect(() => {
        if(!_.isEmpty(submitMsg)) {
            containerRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [submitMsg]);

    if(_.isEmpty(formId)) {
        return null;
    }

    const CustomSubmit = (props) => (
        <SubmitButton
            {...props}
            uniqueId={uniqueId}
            formId={formId}
            uploadedFiles={uploadedFiles}
            errorMsg={errorMsg}
        />
    );

    const CustomLoading = (props) => (
        <Loading
            {...props}
            forceDisableLoading={forceDisableLoading}
        />
    );

    const sendSubmission = (formData) => {
        const url = backendUrl.substring(0, backendUrl.indexOf('/wp-json'));
        fetch(`${url}/wp-json/gf/v2/forms/${formId}/submissions`, {
            method: 'POST',
            body: formData,
        })
        .then((resp) => resp.json())
        .then((response) => {
            if (response && response.is_valid) {
                const {
                    confirmation_message,
                    confirmation_type,
                    confirmation_redirect,
                    data_layer,
                } = response;
                const {type, link} = confirmation_message || false;

                const confirmType = confirmation_type || type;
                const confirmRedirect = confirmation_redirect || link;

                if(!_.isEmpty(data_layer)) {
                    dataLayer.push(data_layer);
                }
                if (confirmType && confirmRedirect && confirmType === 'redirect') {
                    if (typeof window !== 'undefined') {
                        window.location.replace(confirmRedirect);
                        return false;
                    }
                }
                setSubmitMsg(confirmation_message);
                containerRef.current.scrollIntoView({behavior: 'smooth'});
            } else {
                throw {response};
            }
        }).catch((error) => {
            const defaultMsg = t('gravityFormComp.errorMessage');
            const errorMessage = _.get(error, 'response.validation_messages', defaultMsg);
            setErrorMsg(errorMessage);
        });
    };

    const onSubmitHandler = (formData) => {
        // Check if customFormError has any errors, will be an object with field id
        // and value of true if there is an error
        const errors = Object.values({...customFormError}).filter((e) => e !== false);
        const hasCustomErrors = errors && errors.length > 0;
        setForceDisableLoading(false);

        if(!hasCustomErrors) {
            setErrorMsg('');
            sendSubmission(formData);
        } else {
            // Possibility to handle other errors here if needed,
            // This is used to check that required multi file uploads has minimum of 1 file
            setForceDisableLoading(true);
            setErrorMsg(t('gravityFormComp.errorMessage'));
        }
    };

    // TODO: Multiselect has a bug when adding items and then removing all
    const colors = {
        grey: '#5E5E5E',
        grey30: '#D4D4D4',
        grey35: '#898989',
        purple: '#9933FF',
        purple10: '#f5ebff',
        purple20: '#EBD6FF',
    };
    const customSelectStyles = {
        control: (provided, {isFocused}) => ({
            ...provided,
            borderColor: isFocused ? colors.grey35 : colors.grey30,
            borderBottomColor: isFocused ? colors.purple : colors.grey30,
            borderRadius: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: colors.grey35,
                borderBottomColor: isFocused ? colors.purple : colors.grey35,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '10px 72px 14px 10px',
            minHeight: '54px',
        }),
        input: (provided) => ({
            ...provided,
            fontSize: '1.6rem',
            lineHeight: 'normal',
        }),
        singleValue: (provided) => ({
            ...provided,
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            position: 'absolute',
            top: '9px',
            right: '18px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '8px 6px',
            height: '36px',
        }),
        menu: (provided) => ({
            ...provided,
            margin: '-1px 0 0 0',
            borderRadius: '0px',
            border: `1px solid ${colors.grey35}`,
            borderTopColor: colors.purple,
            boxShadow: 'none',
            zIndex: 2,
        }),
        option: (provided, {isFocused, isSelected}) => ({
            ...provided,
            color: colors.grey,
            fontSize: '1.4rem',
            background: isSelected ? colors.purple : (
                isFocused ? colors.purple10 : 'white'
            ),
        }),
        multiValue: (provided) => ({
            ...provided,
            padding: '2px 4px',
            margin: '4px 0 0 4px',
            fontSize: '1.4rem',
            border: `1px solid ${colors.grey30}`,
            borderRadius: '0px',
            background: 'white',
            '&:hover': {
                borderColor: colors.grey35,
            },
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            fontSize: '1.4rem',
            lineHeight: 1,
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            padding: '0 3px',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            '&:hover': {
                color: colors.purple,
                backgroundColor: colors.purple20,
            },
        }),
    };

    const classes = classNames(
        [s['GravityFormComp']],
        {[s['GravityFormComp--Submitted']]: !_.isEmpty(submitMsg)},
        {[s['GravityFormComp--Error']]: !_.isEmpty(errorMsg)},
    );

    return (
        <div className={classes} ref={containerRef}>
            <div className={s['GravityFormComp__Wrap']}>
                {title &&
                    <h2 className={s['GravityFormComp__Title']}>{title}</h2>
                }

                {description &&
                    <p className={s['GravityFormComp__Description']}>{description}</p>
                }

                {_.isEmpty(submitMsg) && uniqueId && custom &&
                    <div className={s['GravityFormComp__Form']}>

                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <GravityForm
                                formID={formId}
                                backendUrl={backendUrl}
                                onSubmit={onSubmitHandler}
                                jumpToConfirmation={false}
                                dropzoneText={t('gravityFormComp.dropzoneText')}
                                errorMessage={t('gravityFormComp.requiredMessage')}
                                styledComponents={{
                                    ReactSelect: CustomSelect, // Regular select
                                    Loading: CustomLoading,
                                    Button: CustomSubmit,
                                    SelectStyles: customSelectStyles,
                                }}
                                customComponents={custom}
                            />
                        </ErrorBoundary>
                    </div>
                }

                {!_.isEmpty(submitMsg) &&
                    <div
                        className={s['GravityFormComp__Message']}
                        dangerouslySetInnerHTML={{__html: submitMsg}}
                    />
                }
            </div>
        </div>
    );
};

GravityFormComp.propTypes = {
    formId: PropTypes.string,
    backendUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    customComponents: PropTypes.object,
};

GravityFormComp.defaultProps = {
    formId: '',
    backendUrl: '',
    title: '',
    description: '',
    customComponents: {},
};

// Custom submit button to add any extra hidden fields that might be needed
const SubmitButton = ({uploadedFiles, formId, uniqueId, errorMsg, type, children, ...props}) => {
    const {t} = useTranslation();

    // Button text should never be empty, make sure to fallback to a default text
    const displayChildren = ['array', 'object'].includes(typeof(children)) ? children.filter((c) => !_.isEmpty(c)) : children;
    const buttonChildren = _.isEmpty(displayChildren) ? t('gravityFormComp.buttonText') : displayChildren;

    if(type !== 'submit') {
        return (
            <button
                {...props}
                type={type}
            >{buttonChildren}</button>
        );
    }
    return (
        <div className={s['GravityFormComp__Submit']}>

            {!_.isEmpty(errorMsg) && typeof(errorMsg) === 'string' &&
                <div
                    className={s['GravityFormComp__Error']}
                    dangerouslySetInnerHTML={{__html: errorMsg}}
                />
            }

            {!_.isEmpty(errorMsg) && typeof(errorMsg) === 'object' &&
                <div className={s['GravityFormComp__Error']}>
                    {Object.values(errorMsg).map((msg, index) => (
                        <p
                            key={index}
                            dangerouslySetInnerHTML={{__html: msg}}
                        />
                    ))}
                </div>
            }

            <input
                type="hidden"
                className={s['gform_hidden']}
                name="gform_unique_id"
                value={uniqueId}
            />
            <input
                type="hidden"
                name="gform_uploaded_files"
                id={`gform_uploaded_files_${formId}`}
                value={JSON.stringify(uploadedFiles)}
            />
            <button
                {...props}
                type={type}
            >{buttonChildren}</button>
        </div>
    );
};

SubmitButton.propTypes = {
    uploadedFiles: PropTypes.object,
    formId: PropTypes.string,
    uniqueId: PropTypes.string,
    errorMsg: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    type: PropTypes.string,
    children: PropTypes.array,
};

SubmitButton.defaultProps = {
    uploadedFiles: {},
    formId: '',
    uniqueId: '',
    errorMsg: '',
    type: '',
    children: [],
};

// Shouldn't be needed but is required for our styling to work
const CustomSelect = (props) => (
    <ReactSelect {...props} />
);

const CustomReCaptcha = (props) => (
    <ReCaptcha {...props} />
);

const Loading = ({isLoading, forceDisableLoading}) => {
    const {t} = useTranslation();
    if(!isLoading || forceDisableLoading) {
        return null;
    }

    return (
        <div className={s['GravityFormComp__Overlay']}>
            <div className={s['GravityFormComp__Loader']}>
                <span />
            </div>
            <span className="sr-only">{t('gravityFormComp.loading')}</span>
        </div>
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool,
    forceDisableLoading: PropTypes.bool,
};

Loading.defaultProps = {
    isLoading: false,
    forceDisableLoading: false,
};

const ErrorFallback = ({
    error = {},
    ...restProps
}) => {
    const { t } = useTranslation();

    // Handle failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <div className={s['GravityFormComp__Error']}>
            <p>{t('gravityFormComp.errorFallback')}</p>
        </div>
    );
};

ErrorFallback.propTypes = {
    error: PropTypes.object,
};

export default GravityFormComp;
