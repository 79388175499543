import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeHTMLWithUploads} from 'utils/html';
import s from './CoreImage.module.scss';

const CoreImage = ({align, innerHTML, contentMaxWidth}) => {

    const sanitizedHtml = sanitizeHTMLWithUploads(innerHTML);

    const getAttributeValue = (attr) => {
        const regex = new RegExp(`<img.*?${attr}=["|'](.*?)["|']`);
        const match = sanitizedHtml.match(regex);
        return match && match.length > 0 ? match[1] : null;
    };

    // The image max width
    const width = parseInt(getAttributeValue('width'));
    // Get default sizes attribute to prevent change if not needed
    const defaultSizes = getAttributeValue('sizes');

    // Set contentMaxWidth in container or layout to make sure no size larger than content is used

    // Widest sizes depending on alignment are used in page without sidebar
    const fullMaxWidth = 1280;
    const wideMaxWidth = 1028;
    const defaultMaxWidth = 618;

    // If no specific content max width exists, fallback to the largest alignment width
    // Set the max width of the content area used by this component
    const imageMaxWidth = _.isEmpty(contentMaxWidth) ? fullMaxWidth : contentMaxWidth;
    const maxAlignWidth = align === 'full' ? fullMaxWidth : (
        align === 'wide' ? wideMaxWidth : defaultMaxWidth
    );
    const maxWidth = imageMaxWidth < maxAlignWidth ? imageMaxWidth : maxAlignWidth;

    // The uploaded image width or the selected size when including image in editor can be smaller
    // than the content max width, use that size instead to prevent larger image defined in srcset to be used
    const getSizes = (maximumWidth) => {
        // Default align
        const breakpointSizes = [
            {bp: 1440, size: (
                align === 'full' ? fullMaxWidth : (
                    align === 'wide' ? wideMaxWidth : defaultMaxWidth
                )
            )},
            {bp: 1280, size: (
                align === 'full' ? 885 : (
                    align === 'wide' ? 640 : 540
                )
            )},
            {bp: 1024, size: align === 'full' || align === 'wide' ? 608 : 416},
            {bp: 768, size: align === 'full' || align === 'wide' ? 871 : 367},
            {bp: 500, size: align === 'full' || align === 'wide' ? 617 : 436},
        ];
        const sizes = breakpointSizes.map(({bp, size}) => {
            const bpSize = maximumWidth < size ? maximumWidth : size;
            return `(min-width: ${bp}px) ${bpSize}px`;
        });
        return [...sizes, '100vw'].join(', ');
    };

    const sizesWidth = width > maxWidth ? maxWidth : width;
    const newSizes = getSizes(sizesWidth);
    const parsedHtml = newSizes === defaultSizes ? sanitizedHtml : (
        sanitizedHtml.replace(`sizes="${defaultSizes}"`, `sizes="${newSizes}"`)
    );

    const classes = classNames(
        [s['CoreImage']],
        {[s[`CoreImage--Align${_.upperFirst(align)}`]]: align},
    );
    return (
        <div
            className={classes}
            dangerouslySetInnerHTML={{__html: parsedHtml}}
        />
    );
};

CoreImage.propTypes = {
    align: PropTypes.string,
    attrs: PropTypes.object,
    innerHTML: PropTypes.string,
    contentMaxWidth: PropTypes.number,
};

CoreImage.defaultProps = {
    align: null,
    attrs: {},
    innerHTML: '',
    contentMaxWidth: null,
};

export default CoreImage;
